/* feedback */
export const feedbackError = '#E47E7E';
export const feedbackInfo = '#A0D7F2';
export const feedbackSuccess = '#B3D999';
export const feedbackWarning = '#FFC89E';
/* feedback */
export const nuance100 = '#FFFFFF';
export const nuance100Alpha = 'rgba(255, 255, 255, 0.4)';
export const nuance150 = '#B6B8BF';
export const nuance200 = '#9497A1';
export const nuance250 = '#797C86';
export const nuance300 = '#5A5D66';
export const nuance400 = '#363940';
export const nuance500 = '#14161A';
/* thea */
export const thea75 = '#F4F5F9';
export const thea150 = '#EAEBF3';
export const thea200 = '#A3B6D7';
export const thea300 = '#1A489A';
export const thea400 = '#263A62';
export const thea500 = '#1F1F1D';
export const theaShadow = 'rgb(26, 72, 154, 12%)';
export const theaBoxShadow = `1px 3px 12px 0px ${theaShadow}`;
/* tonique */
export const tonique1 = '#F9C83E';
export const tonique2 = '#A6213C';
export const tonique3 = '#D91E4C';
export const tonique4 = '#2D987A';
export const tonique5 = '#74C5AD';
export const tonique6 = '#602056';
export const tonique7 = '#AA4B9D';
export const tonique8 = '#EA6C15';
